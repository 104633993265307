import React from 'react';
import './style.sass';

import BoxNuestraCompania from 'components/Footer/Upperfooter/BoxNuestraCompania';
import BoxDejanosAyudarte from 'components/Footer/Upperfooter/BoxDejanosAyudarte/index.js';
import BoxPoliticasSears from 'components/Footer/Upperfooter/BoxPoliticasSears';
import BoxRedes  from 'components/Footer/Upperfooter/BoxRedes'
import BoxGeneral from './BoxGeneralUpperfooter';

export default function UpperFooter(props) {
    const {
        contentSecion
    } = props

    return (
        <div className="upperfooter">
            {
                contentSecion?.length >= 1
                    ?
                        contentSecion?.map((typeUpperF, index) => {
                            return(
                                typeUpperF?.type === "boxCompany1" ? <BoxNuestraCompania key={index} contentSecion={typeUpperF} /> :
                                typeUpperF?.type === "boxPolicies1" ? <BoxPoliticasSears key={index} contentSecion={typeUpperF} /> :
                                typeUpperF?.type === "boxHelp1" ? <BoxDejanosAyudarte key={index} contentSecion={typeUpperF} /> :
                                typeUpperF?.type === "boxContact1" ? <BoxRedes key={index} contentSecion={typeUpperF} /> :
                                <BoxGeneral key={index} contentSecion={typeUpperF} />
                            )
                        })
                    : null
            }
        </div>
    )
}