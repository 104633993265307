import React from 'react'
import './style.sass'

export default function DisclaimerNotice(props){
    const {
        contentSecion
    } = props
    
    return (
        <div className="disclaimerNotice">
            {contentSecion[0]?.visible
                ?
                    <p>{contentSecion[0]?.title}</p>
                : null
            }
        </div>
    )
}