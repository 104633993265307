import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

export default function BoxNuestraCompania(props) {
    const {
        contentSecion
    } = props

    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className="boxClaroShop">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>{contentSecion?.titleColumn}</p>
            <ul>
                {
                contentSecion?.contentList?.length >= 1
                    ?
                        contentSecion?.contentList.map((vinculos, index) => {
                            console.log('vinculos: ',vinculos)
                            const isExternal = vinculos?.url?.indexOf('https')
                            return(
                                <li key={index}>
                                    {isExternal >= 1
                                        ? <a href={vinculos?.url} title={vinculos?.title}>{vinculos?.title}</a>
                                        : <Link to={vinculos?.url} title={vinculos?.title}  >{vinculos?.title}</Link>
                                    }
                                </li>
                            )
                        })
                    : null
                }
            </ul>
        </div>
    )
}
