import React, {useEffect, useState} from 'react';

import FooterNeswletter from 'components/Footer/FooterNewsletter';
import DisclaimerNotice from 'components/Footer/DisclaimerNotice';
import UpperFooter from 'components/Footer/Upperfooter';
import LowerFooter from 'components/Footer/Lowerfooter';
import { useIsMobile } from 'hooks/useIsMobile';

const ContentFooter = (props) => {
    const {
        respService
    } = props

    return(
        <footer className="nhfooter">
            {respService?.SectionsFooter?.length >= 1
                ?
                    respService?.SectionsFooter.map((respFooter, index) => {
                        return(
                            respFooter?.sectionName === "FooterNeswletter" ? <FooterNeswletter key={index}  contentSecion={respFooter?.contentSecion} /> :
                            respFooter?.sectionName === "UpperFooter" ? <UpperFooter key={index} contentSecion={respFooter?.contentSecion} /> :
                            respFooter?.sectionName === "DisclaimerNotice" ? <DisclaimerNotice key={index} contentSecion={respFooter?.contentSecion} /> :
                            respFooter?.sectionName === "LowerFooter" ? <LowerFooter key={index} contentSecion={respFooter?.contentSecion} /> :
                            null
                        )
                    })
                : null
            }

        </footer>
    )
}

export default function FooterPortal(props){
    const {
        visible = null
    } = props;

    
    const respStorage = window.localStorage.getItem('footerService')
    const [respService, setRespService] = useState(respStorage?.length >= 1 ? JSON.parse(respStorage) : null)
    useEffect(()=>{
        fetch('https://resources.sears.com.mx/medios-plazavip/swift/v1/staticJson/sears/footer.json')
        .then(response => response.json())
        .then(setRespService)
    },[])
    useEffect(()=>{
        if(respService){
            const resp = JSON.stringify(respService)
            window.localStorage.setItem('footerService',resp)
        }
    },[respService])

    const { isMobile } = useIsMobile();
    
    return(
        <>
            {isMobile
                ?
                    isMobile === 'desktop'
                        ?
                            <ContentFooter respService={respService} />
                        :  isMobile === 'movile'
                            ? null
                            :
                                visible &&
                                    <ContentFooter respService={respService} />
                : null
            }
        </>
        
    )
}