import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.sass'

export default function BoxGeneral(props) {
    const {
        contentSecion
    } = props

    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className={`${styles?.boxModuleGeneral}`}>
            {
                contentSecion?.url ?
                    <a href={contentSecion?.url} title={contentSecion?.titleColumn} onClick={toggleClass} aria-hidden>{contentSecion?.titleColumn}</a> :
                    <p onClick={toggleClass} className={menuView ? styles?.active : null} aria-hidden>{contentSecion?.titleColumn}</p>

            }
            {
                contentSecion?.contentList?.length >= 1 ?
                    contentSecion?.contentList?.map((dataGeneral, indexParent) => (
                            dataGeneral?.type === 'list' ?
                                <ul key={indexParent}>
                                    { 
                                        dataGeneral?.content?.length >= 1 ?
                                            dataGeneral?.content?.map((vinculos, index) => {
                                                const isExternal = vinculos?.url?.indexOf('http')
                                                return(
                                                    <li key={index}>
                                                        { 
                                                            vinculos?.url ?
                                                                isExternal >= 0 ? 
                                                                    <a href={vinculos?.url} title={vinculos?.title} >
                                                                        {
                                                                            vinculos?.img ?
                                                                                <img 
                                                                                    src={vinculos?.img?.src} 
                                                                                    alt={vinculos?.title} 
                                                                                    width={vinculos?.img?.width} 
                                                                                    height={vinculos?.img?.height} 
                                                                                    loading="lazy" 
                                                                                /> :
                                                                            vinculos?.title}

                                                                    </a> : 
                                                                    <Link to={vinculos?.url} title={vinculos?.title} >
                                                                        {
                                                                            vinculos?.img ?
                                                                                <img 
                                                                                    src={vinculos?.img?.src}
                                                                                    alt={vinculos?.title} 
                                                                                    width={vinculos?.img?.width} 
                                                                                    height={vinculos?.img?.height} 
                                                                                    loading="lazy" 
                                                                                /> :
                                                                            vinculos?.title
                                                                        }
                                                                    </Link> :
                                                                vinculos?.title
                                                        }
                                                    </li>
                                                )
                                            })
                                        : null
                                    }
                                </ul> : 
                            dataGeneral?.type === 'listSocial' ?
                                <div className={`${styles?.redesLogos}`} key={indexParent}>
                                    <ul>
                                        { 
                                            dataGeneral?.content?.length >= 1 ?
                                                dataGeneral?.content?.map((vinculosRS, index) => (
                                                    <li key={index}>
                                                        <a 
                                                            href={vinculosRS?.url} 
                                                            title={vinculosRS?.title} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img 
                                                                src={vinculosRS?.img?.src} 
                                                                alt={vinculosRS?.title} 
                                                                width={vinculosRS?.img?.width} 
                                                                height={vinculosRS?.img?.height} 
                                                                loading="lazy" 
                                                            />
                                                        </a> 
                                                    </li>
                                                ))
                                            : null
                                        }
                                    </ul> 
                                </div> :
                            dataGeneral?.type === 'paragraph' ?
                                <div key={indexParent} className={styles?.paragraph}>
                                    { 
                                        dataGeneral?.content?.length >= 1 ?
                                            dataGeneral?.content?.map((vinculos, index) => (
                                                vinculos?.url ?
                                                    <a 
                                                        href={vinculos?.url} 
                                                        title={vinculos?.title} 
                                                        target="_blank" 
                                                        rel="noopener noreferrer"
                                                        key={index}
                                                    >
                                                        {
                                                            vinculos?.img ?
                                                                <img 
                                                                    src={vinculos?.img?.src} 
                                                                    alt={vinculos?.title} 
                                                                    width={vinculos?.img?.width} 
                                                                    height={vinculos?.img?.height} 
                                                                    loading="lazy" 
                                                                />  :
                                                            vinculos?.title
                                                        }
                                                    </a> :
                                                <p key={index}>
                                                    {
                                                        vinculos?.img  ?
                                                            <img 
                                                                src={vinculos?.img?.src} 
                                                                alt={vinculos?.title} 
                                                                width={vinculos?.img?.width} 
                                                                height={vinculos?.img?.height} 
                                                                loading="lazy" 
                                                            />  :
                                                        vinculos?.title
                                                    }
                                                </p>
                                            ))
                                        : null
                                    }
                                </div> :
                            null
                        ))
                : null
            }
        </div>
    )
}