import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionItem } from 'react-light-accordion';
import Loading from 'components/Loading'


const DescriptModule = (props) => {
    const {
        description,
        viewType
    } = props
    return(
        <p className={viewType === 'desk' ? "answers" : "pSliderL"} dangerouslySetInnerHTML={{__html: description}}></p>
    )
}

const ListModule = (props) => {
    const {
        description,
        viewType
    } = props
    return(
        <ul className={viewType === 'desk' ? "ulD2" : "ulDeclaracionesDiscAccordion" }>
            {
                description?.length >= 1
                    ?
                        description.map(desc => 
                            <li className={viewType === 'desk' ? 'liD2' : '' } dangerouslySetInnerHTML={{__html: desc}}></li>
                        ) 
                    : null
            }
        </ul>
    )
}

const ColumnModule = (props) => {
    const {
        description,
        viewType
    } = props
    return(
        <div>
            <div className='tablePayment inUl'>
                {description?.length  >= 1
                    ?
                        viewType === 'desk'
                            ?
                                <>
                                    <div>
                                        {description.map(firstS => <p dangerouslySetInnerHTML={{__html: firstS?.first}}></p>)}
                                    </div>
                                    <div>
                                        {description.map(lastS => <p dangerouslySetInnerHTML={{__html: lastS?.last}}></p>)}
                                    </div>
                                </>
                            :
                                <div className="pSliderL">
                                     {description.map(firstS => <p dangerouslySetInnerHTML={{__html: `${firstS?.first} : ${firstS?.last}` }}></p>)}
                                </div>

                    : null        
                }
            </div>
        </div>
    )
}

const FaqsDinamic = (props) => {

    const {
        respService
    } = props

    const [titleSection, setTitleSection] = useState(null)
    const [faqsSection, setfaqsSection] = useState(null)
    
    useEffect(()=>{
        if(respService){
            setTitleSection(respService?.section)
            setfaqsSection(respService?.questions)
        }
    },[respService])

    return(
        <div className="sectionContent">
            <section className="sectionTitleAuto">
                <div className="divTitle">
                    {titleSection
                        ? 
                            <p>{titleSection}</p>
                           : null 
                    }
                </div>
                <div className="contentDudas">
                    {faqsSection?.length >= 1
                        ? 
                            faqsSection.map((questions, index) => {
                                return(
                                    <div className="dudas2 espacio2" key={index}>
                                        <h3>{questions?.title}</h3>
                                        {questions?.description?.length >= 1
                                            ? 
                                                questions?.description.map((description, index) => {
                                                    return (
                                                        description?.type === "desc" ? <DescriptModule key={index} description={description?.value} viewType="desk" /> :
                                                        description?.type === "list" ? <ListModule key={index} description={description?.value} viewType="desk" /> :
                                                        description?.type === "two-column" ? <ColumnModule key={index} description={description?.value} viewType="desk" /> :
                                                        null
                                                    )
                                                })
                                            : null
                                        }
                                    </div>
                                )
                            }
                            )
                        : null
                    }
                </div>
            </section>
        </div>
    )
}

const FaqsDinamicMov = (props) => {
    const {
        respService,
        onClose
    } = props

    const [titleSection, setTitleSection] = useState(null)
    const [faqsSection, setfaqsSection] = useState(null)
    
    useEffect(()=>{
        if(respService){
            setTitleSection(respService?.section)
            setfaqsSection(respService?.questions)
        }
    },[respService])

    return(
        <div className="contentModal contentModalPadd">
            <div className="contentClose contentC">
                <div className="btn close" onClick={onClose} ></div>
                <p className="title">{titleSection}</p>
            </div>

            <div className="dataModal">
                <div className="dataModal padding10PF">
                    <div className="boxAN">
                        <div className="bxSlider">
                            <Accordion atomic={true}>
                                
                                {faqsSection?.length >= 1
                                    ? 
                                        faqsSection.map((questions, index) => {
                                            return(
                                                <AccordionItem title={questions?.title}>
                                                    {questions?.description?.length >= 1
                                                        ? 
                                                            questions?.description.map((description, index) => {
                                                                return (
                                                                    description?.type === "desc" ? <DescriptModule key={index} description={description?.value} viewType="mov" /> :
                                                                    description?.type === "list" ? <ListModule key={index} description={description?.value} viewType="mov" /> :
                                                                    description?.type === "two-column" ? <ColumnModule key={index} description={description?.value} viewType="mov" /> :
                                                                    null
                                                                )
                                                            })
                                                        : null
                                                    }
                                                </AccordionItem>
                                            )
                                        })
                                    : null
                                }
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function FaqCreditoDesktop(props) {
    const {
        respService
    } = props
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);


    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>
            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>
                    <FaqsDinamic 
                        respService={respService}
                    />
                </section>
            </main>
            <Footer />
        </>
    )
}

export function FaqCreditoMobile(props) {
    const {
        respService
    } = props
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    return (
        <div className="modalDetallePedido full">
            <FaqsDinamicMov 
                respService={respService}
                onClose={onClose}
            />
        </div>
    )
}

export function FaqCredito() {
    const { isMobile } = useIsMobile();
    
    const [respService, setRespService] = useState()
    useEffect(() => {
        window.ineum('page', 'Preguntas Frecuentes Crédito');
        window.scrollTo(0, 0);

        fetch('https://resources.sears.com.mx/medios-plazavip/swift/v1/staticJson/faqs_credito.json')
        .then(response => response.json())
        .then(setRespService)
    }, [])

    return (
        <>
            { !respService && <Loading />}
            {isMobile &&
                isMobile === 'desktop'
                    ? 
                        <FaqCreditoDesktop
                            respService={respService}
                        />
                        
                    : isMobile === 'movile'
                        ? null
                        : 
                            <FaqCreditoMobile 
                                respService={respService}
                            />
            }
        </>
    )
}